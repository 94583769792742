import React from "react";
import { Helmet } from "react-helmet";




const MetaImage = ({ image, location }) => {

    const { origin } = location;


    const fullUrl = `${origin}${image && image.fixed.src}`;

    return <Helmet>
        {image && <meta property="og:image" content={fullUrl} />}
        {image && <meta property="twitter:image" content={fullUrl} />}
    </Helmet>
}

export default MetaImage;