import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

const Sidebar = () => {
  const data = useStaticQuery(
    graphql`query {
      categories: allArticle {
        group(field: categories) {
            fieldValue
            nodes {
                title
                categories
            }
        }
    }
  }`)
  const categories = data.categories.group.map(g => g.fieldValue);

  return <ul className="fixed z-50">
    {categories.map(category => <li className="capitalize py-1 my-1" key={category}><Link to={`/${category}`} >{category}</Link></li>)}
  </ul>
};
export default Sidebar;
