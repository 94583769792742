import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import Logo from './Logo';
import MobileSidebar from './MobileSidebar';

const Navbar = () => {

    const data = useStaticQuery(
        graphql`query {
            site {
                siteMetadata {
                    slogan
              }
          }
      }`)
    const { slogan } = data.site.siteMetadata;

    const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);

    return <div className="" style={({ paddingBottom: '71px' })}>
        <nav className="fixed top-0 inset-x-0 z-50 bg-white px-4 py-6 lg:px-8 flex items-center justify-between" style={({ filter: 'drop-shadow(0px 4px 7px rgba(206, 206, 206, 0.13))' })}>
            <div className="flex items-center">

                <Link to="/"><Logo className="w-20 mr-2" /></Link><h1 className="pl-2 border-l border-primary-400 text-xs" style={({ color: '#5E5E5E' })}>
                    {slogan}
                </h1>
            </div>


            <ul className="hidden lg:flex">
                <li className="font-medium ml-3"><a href="#">Naujienlaiškis</a></li>
                <li className="font-medium ml-3"><a href="#">Apie mus</a></li>
            </ul>

            <button className="lg:hidden" aria-label="Toggle menu" onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                {!isMobileMenuOpen && <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                }
                {isMobileMenuOpen && <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>}
            </button>
        </nav >
        {
            isMobileMenuOpen &&
            <MobileSidebar style={({ top: '71px' })}>
                <ul className="">
                    <li className="font-medium"><a href="#">Naujienlaiškis</a></li>
                    <li className="font-medium"><a href="#">Apie mus</a></li>
                </ul>

            </MobileSidebar>
        }
    </div>
};
export default Navbar;