

import React from 'react';

const Logo = ({ className }) => {

    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 234.74" className={className}>
        <polygon points="0 0 1.88 234.74 48.83 234.74 48.83 46.95 95.78 46.95 95.78 140.84 142.72 140.84 142.72 46.95 189.67 46.95 189.67 234.74 236.62 234.74 236.62 0 0 0" />
        <path d="M283.57,0V234.74h46.95v-47h46.94v47h47V0Zm93.89,140.85H330.52V47h46.94Z" />
        <polygon points="471.36 0 471.36 46.95 565.26 46.95 565.26 93.9 471.36 93.9 471.36 234.74 612.21 234.74 612.21 187.79 518.31 187.79 518.31 140.84 612.21 140.84 612.21 0 471.36 0" />
        <polygon points="659.15 0 659.15 234.74 800 234.74 800 187.79 706.1 187.79 706.1 140.84 753.05 140.84 753.05 93.9 706.1 93.9 706.1 46.95 800 46.95 800 0 659.15 0" />
        <rect fill="#5fc196" x="95.77" y="187.79" width="46.95" height="46.95" />
    </svg>
};
export default Logo;