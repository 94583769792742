import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';


const MobileSidebar = ({ children, style }) => {
  const data = useStaticQuery(
    graphql`query {
      categories: allArticle {
        group(field: categories) {
            fieldValue
            nodes {
                title
                categories
            }
        }
    }
  }`)
  const categories = data.categories.group.map(g => g.fieldValue);

  return <div className="fixed right-0 bottom-0 overflow-auto bg-white py-4 px-8 w-10/12 z-50" style={style}>
    <div className="mb-8">{children}
    </div>

    <ul>
      {categories.map(category => <li className="capitalize py-1 my-1" key={category}><Link to={`/${category}`}>{category}</Link></li>)}
    </ul>
  </div>
}
export default MobileSidebar;
