import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import MazeTvWidget from './MazeTvWidget';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
    const data = useStaticQuery(
        graphql`query {
            site {
                siteMetadata {
                    title
                    slogan
              }
          }
      }`)
    const { title, slogan } = data.site.siteMetadata

    return <main className="min-h-screen h-full bg-gray-100">
        <Helmet>
            <title>{title} - {slogan}</title>
        </Helmet>
        <Navbar />
        <div className="container mx-auto px-4 mt-14 lg:px-8 ">
            <div className="flex flex-wrap -mx-4 lg:-mx-8">
                <section className="hidden px-4 lg:block lg:w-3/12 lg:px-8">
                    <Sidebar />
                </section>

                <section className="w-full px-4 lg:w-6/12 lg:px-8">
                    {children}
                </section>

                <section className="w-full px-4 mb-8 lg:w-3/12 lg:px-8">
                    <MazeTvWidget />
                </section>
            </div>
        </div>
    </main>
};
export default Layout;