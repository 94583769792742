

import React from 'react';

const MazeTvWidget = ({ className }) => {
    const url = 'https://maze.lt/api/v2/streamers';
    const [streamers, setStreamers] = React.useState();
    React.useEffect(() => {
        fetch(url).then(r => r.json()).then(setStreamers);
    }, [url]);

    return (
        <div className="bg-white shadow p-4 pb-0" >
            <h2 className="text-sm flex items-center mb-4">
                <svg className="mr-2" width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="3" cy="3" r="3" fill="#5FC096" />
                </svg>
                Dabar transliuoja
            </h2>
            <ul className="mb-6">
                {streamers && streamers.slice(0, 10).map(s => {
                    return (
                        <li className=" mb-4" key={s.id}>
                            <a className="flex items-center" target="_blank" rel="noreferrer" href={`http://twitch.tv/${s.twitch}`}>
                                <img src={s.logo || s.screenshot} alt={`${s.twitch} avatar`} className="rounded-full mr-2 flex-shrink-0 w-8" />
                                <div>
                                    <h5 className="text-xs mb-1">{s.twitch}</h5>
                                </div>
                            </a>
                        </li>
                    )
                })}
            </ul>

            <a href="https://tv.maze.lt" target="_blank" rel="noreferrer" className="-mx-4 py-2 block bg-primary-400 text-white text-xs text-center">
                Eiti į <b className="text-black">mazeTV</b></a>
        </div >
    );
};
export default MazeTvWidget;